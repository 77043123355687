
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link} from "react-router-dom";
import loader from "../Images/loader.gif";
import Sidebg from "../Images/sidebg1.png";
import { useFormik } from "formik";
import { signUpSchema } from "../schemas";

const initialValues = {
    name: "",
    email: "",
    password: "",
    phone: ""
};
function SignUp() {
    const [buttonPopup, setButtonPopup] = useState(false);
    const [popAnimation, setPopAnimation] = useState("successMsg");
    const [errorEmail, setErrorEmail] = useState("");
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState("");

    const [loginbtnClass, setLoginBtnClass] = useState("btn-link");
    const [showLoader, setShowLoader] = useState(false);
     const [visible, setVisible] = useState(false);

     const handleShow = () => {
         setVisible(!visible);
     };
    const navigate = useNavigate();
      const {
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
      } = useFormik({
          initialValues: initialValues,
          validationSchema: signUpSchema,
          onSubmit: (values, action) => {
             
              action.resetForm();
          },
      });
    const logIn = () => {

        if(phone === ""){
            setPhoneError("Phone number required")
        }
        else if(isNaN(phone)){
            setPhoneError("Enter valid phone")
        }else if(phone.length !== 10){
            setPhoneError("Enter valid phone")
        }else{
            setPhoneError("")
        }

        if(phoneError === ""){
            setLoginBtnClass("btn-link disable");
            setShowLoader(true);
            const formData = new FormData();
           
            formData.append("name", values.name);
            formData.append("email", values.email);
            formData.append("password", values.password);
            formData.append("phone", phone);
           
           
           
    
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${window.token}`,
            },
            body: formData,
        };
        fetch(`${window.url}/register`, requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setLoginBtnClass("btn-link");
                setShowLoader(false);
            if(data.error === 0){
                    setTimeout(() => {
                        setButtonPopup(true);
                    }, "0");

                    setTimeout(() => {
                        setPopAnimation("successMsg active");
                    }, "100");
                // navigate("/login")
            }
            if(data.error === 1){
                if(data.msg.email === "email already registered") {
                    setErrorEmail("email already registered");
                }

                if(data.msg.phone != null) {
                    setErrorEmail(data.msg.phone);
                }
            }
                
            })
        }
    
    };
      
  function removePop() {
      setTimeout(() => {
          setButtonPopup(true);
      }, "600");
      setTimeout(() => {
          setPopAnimation("successMsg");
      }, "100");

      navigate("/login");
  }
    useEffect(() => {
    
    }, [navigate]);    


    function checkPhone(phoneNumber){
        setPhone(phoneNumber)
        if(phone === ""){
            setPhoneError("Phone number required")
        }
        else if(isNaN(phone)){
            setPhoneError("Enter valid phone")
        }else if(phone.length !== 9){
            setPhoneError("Enter valid phone")
        }else{
            setPhoneError("")
        }

        
    }

    return (
        <>
            <section className="Login_sec signUpSec">
                <div className="sideImg">
                    <img src={Sidebg} alt="sidebg" />
                </div>

                <div className="container">
                    <div className="headSec">
                        <h3>Sign Up</h3>
                        <p>Create a new account</p>
                    </div>
                    <div className="loginForm">
                        <form onSubmit={handleSubmit}>
                            <div className="field">
                                <input
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Name"
                                    className="form-control"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.name && touched.name ? (
                                    <p className="form-error">{errors.name}</p>
                                ) : null}
                            </div>

                            <div className="field">
                                <input
                                    type="text"
                                    autoComplete="off"
                                    placeholder="phone"
                                    className="form-control"
                                    name="phone"
                                    value={phone}
                                    onChange={(e) => checkPhone(e.target.value)}
                                    onBlur={handleBlur}
                                />
                                { phoneError ? (
                                    <p className="form-error">{phoneError}</p>
                                ) : null}
                            </div>

                            <div className="field">
                                <input
                                    type="email"
                                    placeholder="Email"
                                    className="form-control"
                                    name="email"
                                    autoComplete="off"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />

                                <p className="form-error">{errorEmail}</p>
                                {errors.email && touched.email ? (
                                    <p className="form-error">{errors.email}</p>
                                ) : null}
                            </div>
                            <div className="field">
                                <input
                                    type={visible ? "text" : "password"}
                                    placeholder="Password"
                                    className="form-control"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.password && touched.password ? (
                                    <p className="form-error">
                                        {errors.password}
                                    </p>
                                ) : null}
                                 <i className={visible ? " fas fa-eye-slash" : " fas fa-eye"} onClick={handleShow}>
                                    
                                </i>
                            </div>

                            <button
                                type="submit"
                                className={loginbtnClass}
                                disabled={showLoader}
                                onClick={logIn}
                            >
                                Sign Up
                                {showLoader ? (
                                    <div className="loader">
                                        <img src={loader} alt="loader" />
                                    </div>
                                ) : null}
                            </button>
                            {/* <p>Or sign up with other account </p>
                            <div className="otherAccount">
                                <ul>
                                    <li>
                                        <Link to="/login">
                                            <img src={googleImg} alt="google" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/login">
                                            <img
                                                src={facebookImg}
                                                alt="facebook"
                                            />
                                        </Link>
                                    </li>
                                </ul>
                            </div> */}
                            <p className="text">
                                Already have an account ?
                                <Link to="/login" className="formBtn">
                                    sign in
                                </Link>
                            </p>
                        </form>
                    </div>
                </div>

                {buttonPopup ? (
                    <div className="successPopUp">
                        <div className={popAnimation}>
                            {/* <img src={successImg} alt="successImg" /> */}
                            <h5>You Registered successfully </h5>

                            <p>please verify your email to be login</p>
                            <button className="btn-link" onClick={removePop}>
                                Ok
                            </button>
                        </div>
                    </div>
                ) : null}
            </section>
        </>
    );
}
export default SignUp;
