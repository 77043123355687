import "./Style.css";
import { Route, Routes } from "react-router-dom";
import SplashScreen from "./routes/SplashScreen";
import Login from "./routes/Login";
import SignUp from "./routes/SignUp";
import Services from "./routes/Services";
import ServiceListing from "./routes/ServiceListing";
import ServiceListInner from "./routes/ServiceListInner";
import Enquiry from "./routes/Enquiry";
import ForgetPassword from "./routes/ForgetPassword";
import EditProfile from "./routes/EditProfile";
import ChangePassword from "./routes/ChangePassword";
import EnquiryCat from "./routes/EnquiryCat"; 
import cookie from 'react-cookies';
import { useState } from "react";

export default function App() {
    
    const [token, setToken] = useState();

    const auth = () => {
        
        if(!token){
        const localtoken = localStorage.getItem('email');
        if(!localtoken)
            return false;
        else
            return true;
        }else{
        return true;
        }
        
    } 




    return (
        <div className="App">
            {/* {localStorage.getItem("email")} */}
            <Routes>
                {/* <Route path="*" element={<Services />} /> */}
                <Route path="/" element={<Services setToken={setToken} />} />
                <Route path="/login" element={auth() ? <Services setToken={setToken} /> : <Login  setToken={setToken} />} />
                <Route path="/signup" element={<SignUp setToken={setToken}  />} />
                {/* <Route path="/service" element={<Services />} /> */}
                <Route
                    path="/serviceListing/:id"
                    element={<ServiceListing setToken={setToken} />}
                />
                <Route
                    path="/serviceListInner/:id"
                    element={<ServiceListInner setToken={setToken} />}
                />
                <Route path="/enquiry/:id" element={auth() ? <Enquiry setToken={setToken} /> : <Login setToken={setToken} />} />
                <Route path="/enquiry/:id/:cat_id" element={auth() ? <EnquiryCat setToken={setToken} /> : <Login setToken={setToken} />} />
                <Route path="/forgetPassword" element={<ForgetPassword setToken={setToken}/>}/>
                <Route path="/editProfile" element={auth() ? <EditProfile setToken={setToken} /> : <Login setToken={setToken} />} />
                <Route path="/changePassword" element={auth() ? <ChangePassword setToken={setToken} /> : <Login setToken={setToken} />} />
            </Routes>
        </div>
    );
}
