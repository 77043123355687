import { useNavigate } from "react-router-dom";



function Back(){

    const navigate = useNavigate();

    function back(){
        navigate(-1);
    }

    return (
        <div className="backBtn">
                    <i class="fas fa-arrow-left" onClick={back}></i>
                    {/* <Link to={"/serviceListing/" + serviceDetails.id}>
                        <i class="fas fa-arrow-left"></i>
                    </Link> */}
        </div>
    )
}

export default Back;