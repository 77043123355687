import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import Sidebg from "../Images/sidebg1.png";
import loader from "../Images/loader.gif";
import { useFormik } from "formik";
import { signUpSchema } from "../schemas";
import { useNavigate } from "react-router-dom";
import cookie from 'react-cookies';

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";


const initialValues = {
    email: "",
    password: "",
};
function Login({setToken}) {

    const [errorEmail, setErrorEmail] = useState("");
    const [errorAlert, setErrorAlert] = useState("error-msg");
    const [errorMsgText, setErrorMsgText] = useState("");

    const [loginbtnClass, setLoginBtnClass] = useState("btn-link");
    const [showLoader, setShowLoader] = useState(false);
    
    const [visible, setVisible] = useState(false);

    const handleShow = () =>{
        setVisible(!visible);
    }
    const navigate = useNavigate();
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues: initialValues,
            validationSchema: signUpSchema,
            onSubmit: (values, action) => {
                action.resetForm();
            },
        });
    const LoginSec = () => {

        setLoginBtnClass("btn-link disable");
        setShowLoader(true);

        const formData = new FormData();

        formData.append("name", values.name);
        formData.append("email", values.email);
        formData.append("password", values.password);

        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${window.token}`,
            },
            body: formData,
        };
        fetch(`${window.url}/login`, requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setLoginBtnClass("btn-link");
                setShowLoader(false);
                if (data.error === 0) {
                    var now = new Date();
                    now.setTime(now.getTime() + 1 * 3600 * 1000);
                    // var expires = now.toUTCString()
                    localStorage.setItem("email", values.email)
                    localStorage.setItem("name", data.userDetails.name)
                    localStorage.setItem("phone", data.userDetails.phone)
                    localStorage.setItem("u_id", data.userDetails.id)
                    // cookie.save("email", values.email,{maxAge: 1000 , secure: false});
                    // cookie.save("name", data.userDetails.name,{ maxAge: 604800,secure: false});
                    // cookie.save("phone", data.userDetails.phone,{maxAge: 604800,secure: false});
                    // cookie.save("u_id", data.userDetails.id,{maxAge: 604800,secure: false}); 
                    // document.cookie = `email=${values.email}`
                    // document.cookie =  'email=' + values.email + '; max-age=36000000 ; path=/';
                    setToken(values.email)
                }
                if (data.error === 1) {
                    if(data.msg.password)
                        setErrorMsgText(data.msg.password);
                    if(data.msg.email)
                        setErrorMsgText(data.msg.email);
                    setErrorAlert("error-msg active");
                    setTimeout(() => {
                        setErrorAlert("error-msg");                      
                    }, 4000); 
                }
            })
    };
      
  useEffect(() => {

  }, [navigate]);


    return (
        <>
            <section className="Login_sec">
                <div className="sideImg">
                    <img src={Sidebg} alt="sidebg" />
                </div>

                <div className="container">
                    <div className="headSec">
                        <h3>Welcome</h3>
                        <p>Sign in to continue</p>
                    </div>
                    <div className="loginForm">
                        <form onSubmit={handleSubmit}>
                            <div className="field">
                                <input
                                    type="email"
                                    placeholder="Email"
                                    className="form-control"
                                    name="email"
                                    autoComplete="off"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <p className="form-error">{errorEmail}</p>
                                {errors.email && touched.email ? (
                                    <p className="form-error">{errors.email}</p>
                                ) : null}
                            </div>
                            <div className="field">
                                <input
                                    type={visible ? "text" : "password"}
                                    placeholder="Password"
                                    className="form-control"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.password && touched.password ? (
                                    <p className="form-error">
                                        {errors.password}
                                    </p>
                                ) : null}
                                <i className={visible ? " fas fa-eye-slash" : " fas fa-eye"} onClick={handleShow}>
                                    
                                </i>
                            </div>

                            <button
                                type="submit"
                                className={loginbtnClass}
                                disabled={showLoader}
                                onClick={LoginSec}
                            >
                                Log in
                                {showLoader ? (
                                    <div className="loader">
                                        <img src={loader} alt="loader" />
                                    </div>
                                ) : null}
                            </button>

                            <p className="text">
                                New user ?
                                <Link to="/signup" className="formBtn">
                                    Create Account
                                </Link>
                            </p>
                            <div className="CheckBox">
                                {/* <div className="signRemainder">
                                    <input
                                        type="checkbox"
                                        id="topping"
                                        name="topping"
                                    />
                                    Remember Me
                                </div> */}
                                <p>
                                    <Link to="/forgetPassword">
                                        Forgot Password?
                                    </Link>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </section>

            <span className={errorAlert}>{errorMsgText}</span>
        </>
    );
}
export default Login;
