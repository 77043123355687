import { Link } from "react-router-dom";
import Logo from "../Images/logo_white.png";

function SplashScreen(){
return (
    <>
        <section className="splashBg">
            <Link to="/service">
                <div className="logo">
                    <img src={Logo} alt="logo" />
                </div>
            </Link>
        </section>
    </>
);
}
export default SplashScreen;