import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import loader from "../Images/loader.gif";
import { useFormik } from "formik";
import { signUpSchema } from "../schemas";
import { useNavigate } from "react-router-dom";
import cookie from 'react-cookies';
const initialValues = {
    email: "",
};
function ForgetPassword({setToken}) {
    const [errorEmail, setErrorEmail] = useState("");
    const [errorAlert, setErrorAlert] = useState("error-msg");
    const [errorMsgText, setErrorMsgText] = useState("");

    const [loginbtnClass, setLoginBtnClass] = useState("btn-link");
    const [showLoader, setShowLoader] = useState(false);

    const navigate = useNavigate();
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues: initialValues,
            validationSchema: signUpSchema,
            onSubmit: (values, action) => {
                action.resetForm();
            },
        });
        
         const resetPass = () => {

        setLoginBtnClass("btn-link disable");
        setShowLoader(true);

        const formData = new FormData();     
        formData.append("email", values.email);
      

        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${window.token}`,
            },
            body: formData,
        };
        fetch(`${window.url}/submitForgotPassword`, requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setLoginBtnClass("btn-link");
                setShowLoader(false);
                if (data.error === 0) {
                    cookie.save("email", values.email, {maxAge: 604800,secure: true});
                     navigate("/login");
                }
                if (data.error === 1) {
                    // if (data.msg.email === "email already registered") {
                    //     setErrorEmail("email already registered");
                    // }
                    setErrorMsgText(data.msg.email);
                    setErrorAlert("error-msg active");
                    setTimeout(() => {
                        setErrorAlert("error-msg");
                    }, 4000);
                }
            })
    };
    useEffect(() => {
        
    }, [navigate]);
    return (
        <>
            <section className="forgotSec">
                <div className="backBtn">
                    <Link to="/login">
                        <i className="fas fa-arrow-left"></i>
                    </Link>
                </div>
                <div className="fotgotCnt">
                    <h3>Forgot Your password ?</h3>
                    <p>Please enter the email you use to sign in</p>
                    <form onSubmit={handleSubmit}>
                        <div className="field">
                            <input
                                type="email"
                                placeholder="Email"
                                className="form-control"
                                name="email"
                                autoComplete="off"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <p className="form-error">{errorEmail}</p>
                            {errors.email && touched.email ? (
                                <p className="form-error">{errors.email}</p>
                            ) : null}
                        </div>
                        <button
                            type="submit"
                            onClick={resetPass}
                            className={loginbtnClass}
                            disabled={showLoader}
                        >
                            Reset Password
                            {showLoader ? (
                                <div className="loader">
                                    <img src={loader} alt="loader" />
                                </div>
                            ) : null}
                        </button>
                    </form>
                </div>
            </section>

            <span className={errorAlert}>{errorMsgText}</span>
        </>
    );
}
export default ForgetPassword;
