import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import cookie from 'react-cookies';
import { useNavigate } from "react-router-dom";
import Header from "../component/Header";
import loader from "../Images/loader.gif";
import loaderImg from "../Images/loaderImg.gif";
import Back from "../component/Back";


function EditProfile({setToken}) {
      const [phone, setPhone] = useState("");
      const [phoneError, setPhoneError] = useState("");
      const user_id = localStorage.getItem("u_id");

      const [loaderContent, setLoaderContent] = useState(true);

      const [errorAlert, setErrorAlert] = useState("error-msg");
      const [successAlert, setSuccessAlert] = useState("success-Msg");
      const [errorMsgText, setErrorMsgText] = useState("");

      

      const [showLoader, setShowLoader] = useState(false);
      const [loginbtnClass, setLoginBtnClass] = useState("btn-link");


      const [name, setName] = useState("");
      const [nameError, setNameError] = useState("");

         const navigate = useNavigate();
         const handleSubmit = (e) => {
            e.preventDefault();

             if(name === ""){
                setNameError("name required")
             }


             if (phone === "") {
                 setPhoneError("Phone number required");
             } else if(isNaN(phone)) {
                 setPhoneError("Enter valid phone");
             }
            else if (phone.length !== 10) {
                    setPhoneError("Enter valid phone");
             }else {
                 setPhoneError("");
             }

             if (phoneError === "" && nameError === "") {

                setShowLoader(true)
                setLoginBtnClass("btn-link disable")
              
                 const formData = new FormData();

                 formData.append("name", name);
                 formData.append("phone", phone);
                 formData.append("id", user_id);

                 const requestOptions = {
                     method: "POST",
                     headers: {
                         Authorization: `Bearer ${window.token}`,
                     },
                     body: formData,
                 };
                 fetch(`${window.url}/editProfile`, requestOptions)
                     .then((response) => {
                         return response.json();
                     })
                     .then((data) => {
                        if (data.error === 1) {
                            setErrorAlert("error-msg active");
                            setErrorMsgText("something went wrong!")
                            setTimeout(() => {
                                setErrorAlert("error-msg");                      
                            }, 4000);
                        }

                        if (data.error === 0) {
                            setShowLoader(false)
                            setLoginBtnClass("btn-link")
                            setSuccessAlert("success-Msg active");
                            setErrorMsgText("profile edited successfully!")
                            setTimeout(() => {
                                setSuccessAlert("success-Msg");                      
                            }, 4000);
                        }
                     })
             }
         };
         const id = localStorage.getItem("u_id");
         function fetchData(){
            const formData = new FormData();
        
            formData.append("id", id);

            const requestOptions = {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${window.token}`,
                },
                body: formData,
            };
            fetch(`${window.url}/profileDetails`, requestOptions)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if(data.error === 0){
                        setName(data.details.name);
                        setPhone(data.details.phone);
                        // setSuccessAlert("success-Msg active");
                        // setTimeout(() => {
                        //     setSuccessAlert("success-Msg");                      
                        // }, 4000);
                        setLoaderContent(false)
                    }
                    else{
                        setErrorAlert("error-msg active");
                        setErrorMsgText("something went wrong!")
                        setTimeout(() => {
                            setErrorAlert("error-msg");                      
                        }, 4000);
                    }
                })
        }
        useEffect(() => {
            fetchData()
        },[]);   


           
            
    function checkPhone(phoneNumber) {
        setPhone(phoneNumber);
        if (phoneNumber === "") {
            setPhoneError("Phone number required");
        } else if (isNaN(phoneNumber)) {
            setPhoneError("Enter valid phone");
        } else if (phoneNumber.length !== 10) {
            setPhoneError("Enter valid phone");
        } else {
            setPhoneError("");
        }
    }

    function checkName(getName){
        setName(getName);
        if(name === ""){
            setNameError("name required")
        }else{
            setNameError("");
        }
    }
    return (
        <>
            {loaderContent ? (
                <div className="loaderSec">
                    <div className="loaderImg">
                        <img src={loaderImg} alt="loader" />
                    </div>
                </div>
            ) : null}


            <section className="serviceList enquirySec">
                <Header setToken={setToken}/>
                <Back/>
                <div className="heading">
                    <h3>Edit Profle</h3>
                </div>
                <div className="innerSec">
                    <div className="container">
                        <form onSubmit={handleSubmit} >
                            <div className="field">
                                <input
                                    type="text"
                                    placeholder="Name"
                                    className="form-control"
                                    name="name"
                                    value={name}
                                    onChange={(e) => checkName(e.target.value)}
                                    onBlur={(e) => checkName(e.target.value)}
                                />
                                { nameError ? (
                                    <p className="form-error">{nameError}</p>
                                ) : null}
                            </div>
                            <div className="field">
                                <input
                                    type="tel"
                                    placeholder="Phone no."
                                    className="form-control"
                                    name="contact_no"
                                    autoComplete="off"
                                    value={phone}
                                    onChange={(e) => checkPhone(e.target.value)}
                                    onBlur={(e) => checkPhone(e.target.value)}
                                />
                                {phoneError ? (
                                    <p className="form-error">{phoneError}</p>
                                ) : null}
                            </div>
                            <button
                                className={loginbtnClass}
                                disabled={showLoader}
                            >
                                Save Changes
                                {showLoader ? (
                                    <div className="loader">
                                        <img src={loader} alt="loader" />
                                    </div>
                                ) : null}
                            </button>
                        </form>
                    </div>
                </div>
            </section>
            <span className={errorAlert}>{errorMsgText}</span>
            <span className={successAlert}>Profile Edited Successfully</span>
        </>
    );
}
export default EditProfile;
