import React, { useEffect, useState, useRef} from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import loaderImg from "../Images/loaderImg.gif";
import Header from "../component/Header";

function Services({setToken}) {
   const[open, setOpen] = useState(false);
   const { code } = useParams();
    const navigate = useNavigate();
    const [users, setUsers] = useState('');
    const [loader, setloader] = useState(true);
    const tokenString = sessionStorage.getItem("email");
    
    const fetchData = () => {
          const requestOptions = {
              method: "GET",
              headers: {
                  Authorization: `Bearer ${window.token}`,
              },
            
          };
      fetch(`${window.url}/serviceCategory`, requestOptions)
          .then((response) => {
              return response.json();
          })
          .then((data) => {
              setUsers(data);
          }).then((data) => {
            setloader(false)
          })
    };

    useEffect(() => {
        fetchData();
    
    }, [navigate]);
    
    function logout(){
       sessionStorage.clear();
       navigate("/service");
    }

    function login(){
        sessionStorage.setItem('url', "/service");
        navigate("/login");
    }
     const handleShowMenu = () => {
         setOpen(!open);
     };
    return (
        <>
            {loader ? (
                <div className="loaderSec">
                    <div className="loaderImg">
                        <img src={loaderImg} alt="loader" />
                    </div>
                </div>
            ) : null}
            <section className="serviceSec">
                {/* <div className="logout">
                    { 
                        tokenString != null ? 
                            <button onClick={logout} className="btn-logout">log Out</button> :
                            <button onClick={login} className="btn-logout">sign up/sign in</button>
                    }
                    
                </div> */}
                <Header setToken={setToken}/>
                <div className="heading">
                    <h3>Service Menu</h3>
                    <p>Our Services Menu</p>
                </div>
                <div className="innerSec">
                    {users.serviceCategories != null ? (
                        <div className="boxListing">
                            {users.serviceCategories.map((user) => (
                                <div className="box" key={user.id}>
                                    <Link to={"/serviceListing/" + user.id}>
                                        <figure>
                                            <img
                                                src={user.image}
                                                alt="boxImg1"
                                            />
                                        </figure>
                                        <p>{user.name}</p>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    ) : null}
                </div>
            </section>
        </>
    );
}
export default Services;
