
import { Link } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import successImg from "../Images/successImg.png";
import loaderImg from "../Images/loaderImg.gif";
import loaderbtn from "../Images/loader.gif";
import Header from "../component/Header";
import Back from "../component/Back";
// import validator from "validator";
import cookie from 'react-cookies';



function Enquiry({setToken}) {
const navigate = useNavigate();
const [loader, setloader] = useState(true);
const { id } = useParams();
const [buttonPopup, setButtonPopup] = useState(false);
const [popAnimation, setPopAnimation] = useState("successMsg");
const[sname, setSName] = useState("")
const [snameId, setSNameId] = useState("");
const [name, setName] = useState("");
const [contact_no, setContactNo] = useState("");
const [email, setEmail] = useState("");
const [request, setRequest] = useState("");



const [nameError, setNameError] = useState(false);
const [contact_noError, setContactNoError] = useState(false);
const [emailError, setEmailError] = useState(false);
const [requestError, setRequestError] = useState(false);
const [showLoader, setShowLoader] = useState(false);
const [error, setError] = useState("");
const tokenString = localStorage.getItem("email");
const nameG = localStorage.getItem("name");
const phoneG = localStorage.getItem("phone");
const u_id   = localStorage.getItem("u_id");


function handleChange(value, name){
    if(name === "name"){
        setName(value);
        if (!name) {
            setNameError(true)
        }else{
           setNameError(false); 
        }
    }

    if (name === "contact_no") {
        setContactNo(value);
        if (!contact_no) {
            setContactNoError(true);
        }else if (contact_no.length !== 9) {
            setContactNoError(true);
        }else if (isNaN(contact_no)) {
            setContactNoError(true);
        } else {
            setContactNoError(false);
        }
    }

    if(name === 'email'){
        setEmail(value)
        const regex =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!email) {
            setEmailError(true);
        } else if (regex.test(email) === false) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
    }

    if (name === "request") {
        setRequest(value);
        if (!request) {
            setRequestError(true);
        } else {
            setRequestError(false);
        }
    }


    
}
 
 function bttnSubmit(e){
    e.preventDefault();
    if (!name) {
        setNameError(true);
    } else {
        setNameError(false);
    }


    if (!contact_no) {
        setContactNoError(true);
    } else if (contact_no.length !== 10) {
        setContactNoError(true);
    } else if (isNaN(contact_no)) {
        setContactNoError(true);
    } else {
        setContactNoError(false);
    }


    const regex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email) {
        setEmailError(true);
    } else if (regex.test(email) === false) {
        setEmailError(true);
    } else {
        setEmailError(false);
    }

    if (!request) {
        setRequestError(true);
    } else {
        setRequestError(false);
    }


    if (
        nameError === false &&
        contact_noError === false &&
        emailError === false &&
        requestError === false
    ) {
        setloader(true)
        const formData = new FormData();
        formData.append("service_id", id);
        formData.append("sname", sname);
        formData.append("name", name);
        formData.append("contact_no", contact_no);
        formData.append("email", email);
        formData.append("requests", request);
        formData.append("u_id", u_id);

        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${window.token}`,
            },
            body: formData,
        };
        
        fetch(`${window.url}/postEnquiry`, requestOptions)
            .then((response) => response.json())
            .then(function (data) {
                setloader(false)
                if (data.error === 0) {
                    setTimeout(() => {
                        setButtonPopup(true);
                    }, "0");

                    setTimeout(() => {
                        setPopAnimation("successMsg active");
                    }, "100");
                }
            });
    }else{
        setloader(false)
        return false;
    } 
 }

 function removePop(){
    setTimeout(() => {
        setButtonPopup(false);
    }, "600");
    setTimeout(() => {
        setPopAnimation("successMsg");
    }, "100");

    navigate("/");
 }
  const fetchServiceName = () => {
      const requestOptions = {
          method: "GET",
          headers: {
              Authorization: `Bearer ${window.token}`,
          },
      };
      fetch(`${window.url}/serviceDetails/` + id, requestOptions)
          .then((response) => {
              return response.json();
          })
          .then((data) => {
             setSName(data.serviceDetails.name);
             setSNameId(data.serviceDetails.id);
          }).then((data) => {
            setloader(false)
          });
  };
  useEffect(() => {


      fetchServiceName();
       setName(nameG);
       setContactNo(phoneG);
       setEmail(tokenString);
  }, [navigate]);
 
    return (
        <>
            {
                loader ? 
                <div className="loaderSec">
                    <div className="loaderImg">
                    <img src={loaderImg} alt="loader" />
                    </div>
                </div> : null
            }
            <section className="serviceList enquirySec">
                <Header setToken={setToken}/>
                <Back/>
                <div className="heading">
                    <h3>Enquiry</h3>
                </div>
                <div className="innerSec">
                    <div className="container">
                        <form>
                            <div className="field">
                                <label
                                    type="text"
                                    placeholder="Service Name"
                                    className="form-control"
                                    name="sName"
                                >
                                    {sname}
                                </label>
                                {error && !sname && (
                                    <span className="invalidInput">
                                        Enter Valid Service Name
                                    </span>
                                )}
                            </div>
                            <div className="field">
                                <input
                                    type="text"
                                    placeholder="Name"
                                    className="form-control"
                                    name="name"
                                    value={name}
                                    onChange={(e) =>
                                        handleChange(e.target.value, "name")
                                    }
                                />
                                {nameError === true ? (
                                    <span className="invalidInput">
                                        Enter Valid Name
                                    </span>
                                ) : null}
                            </div>
                            <div className="field">
                                <input
                                    type="tel"
                                    placeholder="Phone no."
                                    className="form-control"
                                    name="contact_no"
                                    autoComplete="off"
                                    value={contact_no}
                                    onChange={(e) =>
                                        handleChange(
                                            e.target.value,
                                            "contact_no"
                                        )
                                    }
                                   
                                />
                                {contact_noError === true ? (
                                    <span className="invalidInput">
                                        Enter Valid Phone number
                                    </span>
                                ) : null}
                            </div>
                            <div className="field">
                                <input
                                    type="email"
                                    placeholder="Email"
                                    className="form-control"
                                    name="email"
                                    autoComplete="off"
                                    value={email}
                                    onChange={(e) =>
                                        handleChange(e.target.value, "email")
                                    }
                                    onBlur={(e) =>
                                        handleChange(e.target.value, "email")
                                    }
                                    onKeyDown={(e) =>
                                        handleChange(e.target.value, "email")
                                    }
                                    onPaste={(e) =>
                                        handleChange(e.target.value, "email")
                                    }
                                />
                                {emailError === true ? (
                                    <span className="invalidInput">
                                        Enter Valid email
                                    </span>
                                ) : null}
                            </div>
                            <div className="field">
                                <input
                                    type="text"
                                    placeholder="Requests"
                                    className="form-control"
                                    name="request"
                                    onChange={(e) =>
                                        handleChange(e.target.value, "request")
                                    }
                                />
                                {requestError === true ? (
                                    <span className="invalidInput">
                                        Enter Valid request
                                    </span>
                                ) : null}
                            </div>

                            <button className="btn-link" onClick={bttnSubmit} disabled={showLoader}>
                                Send
                                {showLoader ? (
                                    <div className="loader">
                                        <img src={loaderbtn} alt="loader" />
                                    </div>
                                ) : null}
                            </button>
                        </form>
                    </div>
                </div>
                {buttonPopup ? (
                    <div className="successPopUp">
                        <div className={popAnimation}>
                            <img src={successImg} alt="successImg" />
                            <h4>Success</h4>
                            <p>Your enquiry has successfully submitted</p>
                            <button className="btn-link" onClick={removePop}>
                                Ok
                            </button>
                        </div>
                    </div>
                ) : null}
            </section>
        </>
    );
}
export default Enquiry;
