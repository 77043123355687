import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import loader from "../Images/loader.gif";
import { useNavigate } from "react-router-dom";
import Header from "../component/Header";
import Back from "../component/Back";
import cookie from 'react-cookies';


function ChangePassword({setToken}) {
     const id = localStorage.getItem("u_id");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [errorcurrentPassword, seterrorCurrentPassword] = useState("");
    const [nerrorewPassword, seterrorNewPassword] = useState("");
    const [errorconfirmPassword, seterrorConfirmPassword] = useState("");

    const [errorAlert, setErrorAlert] = useState("error-msg");
    const [successAlert, setSuccessAlert] = useState("success-Msg");
    const [errorMsgText, setErrorMsgText] = useState("");

    const [loginbtnClass, setLoginBtnClass] = useState("btn-link");
    const [showLoader, setShowLoader] = useState(false);
    const [visible, setVisible] = useState(false);
    const handleShow = () => {
        setVisible(!visible);
    };
    const navigate = useNavigate();

    function checkCurrentPassword(value){
        setCurrentPassword(value);
        if(value === ""){
            seterrorCurrentPassword("required");
        }else{
            seterrorCurrentPassword("");
        }
        
    }

    function checkNewPassword(value){
        setNewPassword(value);
        if(value === ""){
            seterrorNewPassword("required");
        }else if(value.length < 6){
            seterrorNewPassword("password must be at least 6 characters");
        }else {
            seterrorNewPassword("");
        }
        if(value !== confirmPassword){
            seterrorConfirmPassword("password aren't same");
        }else{
            seterrorNewPassword("");
        }
    }

    function checkConfirmPassword(value){
        setConfirmPassword(value)
        if(value === ""){
            seterrorConfirmPassword("required");
        }else if(value.length <  6){
            seterrorConfirmPassword("password must be at least 6 characters");
        }else if(value !== newPassword){
            seterrorConfirmPassword("password aren't same");
        }else{
            seterrorConfirmPassword("");
        }
    }

    const resetPass = (e) => {
         
        e.preventDefault();

        if(currentPassword === ""){
        seterrorCurrentPassword("required");
        }

        if(confirmPassword === ""){
        seterrorConfirmPassword("required");
        }

        if(newPassword === ""){
        seterrorNewPassword("required");
        }


        if(currentPassword !== "" && confirmPassword !== "" && newPassword !== ""){
            if(errorconfirmPassword === "" && errorcurrentPassword === "" && nerrorewPassword === ""){
            
                setLoginBtnClass("btn-link disable");
                setShowLoader(true);
    
                const formData = new FormData();
                formData.append("id", id);
                formData.append("password", newPassword);
                formData.append("confirmPassword", confirmPassword);
                formData.append("currentPassword", currentPassword);
       
                const requestOptions = {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${window.token}`,
                    },
                    body: formData,
                };
                fetch(`${window.url}/changePassword`, requestOptions)
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        setLoginBtnClass("btn-link");
                        setShowLoader(false);
                        if (data.error === 0) {
                            setSuccessAlert("success-Msg active");
                            setTimeout(() => {
                                setSuccessAlert("success-Msg");                      
                            }, 4000);
                        }
                        if (data.error === 1) {
                           if(data.currentPassword){
                               seterrorCurrentPassword(data.currentPassword)
                           }else{
                                setErrorMsgText(data.msg.email);
                                setErrorAlert("error-msg active");
                                setTimeout(() => {
                                    setErrorAlert("something went wrong");
                                }, 4000);
                           }
                            // if (data.msg.email === "email already registered") {
                            //     setErrorEmail("email already registered");
                            // }
                           //  
                        }
                    })
            }
        }

         

         
     };
     useEffect(() => {
         
     }, [navigate]);

     



    return (
        <>
            <section className="serviceList enquirySec">
                {/* <div className="logout">
                    { 
                        tokenString != null ? 
                            <button onClick={logout} className="btn-logout">log Out</button> :
                            <button onClick={login} className="btn-logout">sign up/sign in</button>
                    }
                    
                </div> */}

                <Header setToken={setToken}/>
                <Back/>
                <div className="heading">
                    <h3>change password</h3>
                </div>
                <div className="innerSec">
                    <div className="container">
                        <form>
                            <div className="field">
                                <input
                                    type="password"
                                    placeholder="Current Password"
                                    className="form-control"
                                    name="password"
                                    value={currentPassword}
                                    onChange={(e) => checkCurrentPassword(e.target.value)}
                                    onBlur={(e) => checkCurrentPassword(e.target.value)}
                                />
                                
                                    <p className="form-error">
                                        {errorcurrentPassword}
                                    </p>
                            </div>
                            <div className="field">
                                <input
                                    type="password"
                                    placeholder="New Password"
                                    className="form-control"
                                    name="password"
                                    value={newPassword}
                                    onChange={(e) => checkNewPassword(e.target.value)}
                                    onBlur={(e) => checkNewPassword(e.target.value)}
                                />
                                
                                    <p className="form-error">
                                        {nerrorewPassword}
                                    </p>
                            </div>
                            <div className="field">
                                <input
                                    type={visible ? "text" : "password"}
                                    placeholder="Confirm Password"
                                    className="form-control"
                                    name="password"
                                    value={confirmPassword}
                                    onChange={(e) => checkConfirmPassword(e.target.value)}
                                    onBlur={(e) => checkConfirmPassword(e.target.value)}
                                />
                             
                                <p className="form-error">
                                    {errorconfirmPassword}
                                </p>
                         
                                <i
                                    className={
                                        visible
                                            ? " fas fa-eye-slash"
                                            : " fas fa-eye"
                                    }
                                    onClick={handleShow}
                                ></i>
                            </div>
                            <button
                                onClick={resetPass}
                                className={loginbtnClass}
                                disabled={showLoader}
                            >
                                Update Password
                                {showLoader ? (
                                    <div className="loader">
                                        <img src={loader} alt="loader" />
                                    </div>
                                ) : null}
                            </button>
                        </form>
                    </div>
                </div>
            </section>
            <span className={errorAlert}>{errorMsgText}</span>
            <span className={successAlert}>Password Changed successfully</span>
        </>
    );
}
export default ChangePassword;
