import { Link } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import cookie from 'react-cookies';

const Header = ({setToken}) => {
    const navigate = useNavigate();
    const tokenString = localStorage.getItem("email");
    const[showList, setShowList] = useState(false);
    const [open, setOpen] = useState(false);
    let menuRef = useRef();
    useEffect(() => {
        let handler = (e) => {
            if (!menuRef.current.contains(e.target)) {
                setOpen(false);
                setShowList(false);
            }
        };
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        };
    });

    const handleShowMenu = () => {
        setOpen(!open);
        setShowList(!showList);
    };

    function logout(){
        localStorage.clear()
        setToken("");
    }

    return(
        <div className="menu-container" ref={menuRef} onClick={handleShowMenu}>
            <button className="menuTrigger" onClick={handleShowMenu}>
            <i className="fas fa-bars"></i>
            </button>
            <div
                        className={`dropDownList ${
                            showList ? "active" : "inactive"
                        }`}
            >
                 <div
                className={`dropdownMenu ${
                    open ? "active" : "inactive"
                }`}
            >
                <ul>
                    <li>
                        <Link to="/">Service</Link>
                    </li>
                    { 
                        tokenString != null? 
                        <>
                            <li>
                                <Link to="/changePassword">
                                    Change Password
                                </Link>
                            </li>
                            <li>
                                <Link to="/editProfile">Edit Profile</Link>
                            </li>
                            <li>
                                <Link to="/" onClick={logout}>Log Out</Link>
                            </li>
                        </> 
                        : <li>
                            <Link to="/login">Log In</Link>
                          </li>
                    }
                    
                </ul>
            </div>

            </div>
           
        </div>
    );
}

export default Header;