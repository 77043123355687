import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import loaderImg from "../Images/loaderImg.gif";
import Header from "../component/Header";
import Back from "../component/Back";

function ServiceListInner({setToken}) {

    const navigate = useNavigate();
     const { id } = useParams();
       const [serviceDetails, setServiceDetails] = useState("");
       const [loader, setloader] = useState(true);
       const fetchData = () => {
         const requestOptions = {
             method: "GET",
             headers: {
                 Authorization: `Bearer ${window.token}`,
             },
         };
           fetch(`${window.url}/serviceDetails/` + id, requestOptions)
               .then((response) => {
                   return response.json();
               })
               .then((data) => {
                   setServiceDetails(data);
               }).then((data) => {
                setloader(false)
               });
       };

       useEffect(() => {
           fetchData();
       },[]);


    

    return (
        <>
            {loader ? (
                <div className="loaderSec">
                    <div className="loaderImg">
                        <img src={loaderImg} alt="loader" />
                    </div>
                </div>
            ) : null}
            <section className="serviceList serviceListInr">
                {/* <div className="logout">
                    { 
                        tokenString != null ? 
                            <button onClick={logout} className="btn-logout">log Out</button> :
                            <button onClick={login} className="btn-logout">sign up/sign in</button>
                    }
                    
                </div> */}

                <Header setToken={setToken}/>
                <Back/>
                {serviceDetails.serviceDetails?.back_image != null ? (
                <div className="headImg">
                    <figure>
                        
                            <img
                                src={serviceDetails.serviceDetails?.back_image}
                                alt="HeadImg"
                            />
                    </figure>
                </div>): 
                    <div className="heading">
                        <h3>{serviceDetails?.serviceDetails?.name}</h3>
                    </div> 

                }
                

                <div className="innerSec">
                    {serviceDetails.serviceDetails != null ? (
                        <div className="container">
                            {serviceDetails.serviceDetails?.back_image != null ?
                                <h4>{serviceDetails.serviceDetails.name}</h4>
                            : null}
                            <p style={{whiteSpace: "pre-wrap"}}>{serviceDetails.serviceDetails.details}</p>

                            <Link
                                to={
                                    "/enquiry/" +
                                    serviceDetails.serviceDetails.id
                                }
                                className="btn-enquiry"
                            >
                                enquiry
                            </Link>
                        </div>
                    ) : null}
                </div>
            </section>
        </>
    );
}
export default ServiceListInner;
