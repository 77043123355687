import * as Yup from "yup";
export const signUpSchema = Yup.object({
    name: Yup.string()
        .required("Username is required")
        .min(3, "Username must be at least 3 characters")
        .max(20, "Username must not exceed 20 characters"),
    // checkEmail: Yup.boolean(),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    // email: Yup.string()
    //     .email("Email is invalid")
    //     .required("Email is required")
    //     .when("checkEmail", {
    //         is: true,
    //         then: Yup.string().test({
    //             message: () => "Email already exists",
    //             test: async (values) => {
    //                 if (values) {
    //                     try {
    //                         let response = await fetch(
    //                             `${window.url}/register`,
    //                             {
    //                                 method: "POST",
    //                                 headers: {
    //                                     "Content-Type": "application/json",
    //                                 },
    //                                 body: JSON.stringify({ email: values }),
    //                             }
    //                         );
    //                         if (response.ok) {
    //                             return true;
    //                         } else {
    //                             return false;
    //                         }
    //                     } catch (error) {
    //                         console.log(error);
    //                     }
    //                 }
    //             },
    //         }),
    //     }),
    phone: Yup.string()
        .min(2)
        .max(25)
        .required("Please enter your phone number"),
    password: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters")
        .max(40, "Password must not exceed 40 characters"),
    confirmPassword: Yup.string()

        .required("Please retype your password.")
        .oneOf([Yup.ref("password")], "Your passwords do not match."),
});
