import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
window.url = "https://app.bharosa.net.in/admin/api";
// window.url = "http://192.168.1.116/webdev/Bharosa/backend/api";

window.token ="qa0!aseq!brl7rexoBRE";
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);
