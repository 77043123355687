import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import loaderImg from "../Images/loaderImg.gif";
import Header from "../component/Header";

function ServiceListing({setToken}) {
    const { id } = useParams();
    const [loader, setloader] = useState(true);
    const [serviLst, setServiLst] = useState("");
      const fetchData = () => {
          const requestOptions = {
              method: "GET",
              headers: {
                  Authorization: `Bearer ${window.token}`,
              },
          };
          fetch(`${window.url}/serviceListing/` + id, requestOptions)
              .then((response) => {
                  return response.json();
              })
              .then((data) => {
                  setServiLst(data);
              }).then((data) => {
                setloader(false)
              });
      };

      useEffect(() => {
          fetchData();
      },[]);


 
    return (
        <>
            {loader ? (
                <div className="loaderSec">
                    <div className="loaderImg">
                        <img src={loaderImg} alt="loader" />
                    </div>
                </div>
            ) : null}
            <section className="serviceList serviceListInr">
                {/* <div className="logout">
                    { 
                        tokenString != null ? 
                            <button onClick={logout} className="btn-logout">log Out</button> :
                            <button onClick={login} className="btn-logout">sign up/sign in</button>
                    }
                    
                </div> */}

                <Header setToken={setToken}/>
                <div className="backBtn">
                    <Link to="/">
                        <i className="fas fa-arrow-left"></i>
                    </Link>
                </div>
                <div  className={ serviLst.categoryDetails?.cover_image != null ? "headImg" : "heading"}>

                    { serviLst.categoryDetails?.cover_image != null ?

                        <figure>
                                                    
                        <img
                            src={serviLst.categoryDetails?.cover_image}
                            alt="HeadImg"
                        />
                        </figure>

                        : <h3>
                            {serviLst != null
                                ? serviLst?.categoryDetails?.name
                                : null}
                            </h3>
                    }
                    
                    
                </div>
                {
                    serviLst?.categoryDetails?.show_form === 1 ?

                    <div className="innerSec">                  

                       <div className="container">

                       { serviLst.categoryDetails?.cover_image != null ?
                       
                       <h3>
                        {serviLst != null
                            ? serviLst?.categoryDetails?.name
                            : null}
                        </h3>
                        : null }
                        <p style={{whiteSpace: "pre-wrap"}}>{serviLst?.categoryDetails?.description}</p>
                        <Link
                            to={
                                "/enquiry/"+serviLst?.categoryDetails?.id+"/cat"
                            }
                            className="btn-enquiry"
                        >
                            enquiry
                        </Link>
                        </div>
                    </div> 
                    
                    :
                    <div className="innerSec">
                         <div className="container">
                        <h3>
                        { serviLst.categoryDetails?.cover_image != null 
                            ? serviLst?.categoryDetails?.name
                        : null}
                        </h3>
                        {serviLst.services != null ? (
                            <div className="boxListing">
                                {serviLst.services.map((service, key) => (
                                    <div className="box" key={key}>
                                        <Link
                                            to={"/serviceListInner/" + service.id}
                                        >
                                            <figure>
                                                <img
                                                    src={service.image}
                                                    alt="List1"
                                                />
                                            </figure>
                                            <p>{service.name}</p>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="noRecord">
                                <p>No records Found</p>
                            </div>
                        )}
                        </div>
                    </div> 
                }






            </section>
        </>
    );
}
export default ServiceListing;
